import ordersNoQuoteLogo from 'src/assets/Orders/ordersNoQuoteLogo.svg';
import fabricRoll from 'src/assets/Orders/fabric-roll.svg';
import intransitNoQuoteLogo from 'src/assets/Intransit/intransitNoQuoteLogo.svg';
import LocofastAssuredIcon from 'src/assets/Locofast.svg';
import QualityGuaranteeThumsUp from 'src/assets/thumsUp.svg';

export const FABRIC_STATUS = {
  DRAFT: 1,
  QUOTE_REQUESTED: 2,
  QUOTE_SUBMITTED: 3,
  QUOTE_APPROVED: 4,
  MOVED_TO_PRODUCTION: 5,
  SUPPLIERS_ASSIGNED: 6,
  TNA_CREATED: 7,
  GREIGE_DISPATCHED: 8,
  LAB_DIP_STRIKE_OFF_APPROVED: 9,
  BULK_APPROVED: 10,
  DISPATCHED: 11,
  QUERY_CLOSED: 12,
  UNSERVICEABLE: 13,
};

export const INPUT_BOX_ACTION_TYPE = {
  FOCUS: 'focus',
  RESET: 'reset',
  SET: 'set',
};

export const QUERY_DROPPED = [FABRIC_STATUS.QUERY_CLOSED, FABRIC_STATUS.UNSERVICEABLE];

export const LOCOFAST_GUARANTEE_MACHINE_IMAGES_DATA = [
  {
    img: 'https://miscellaneous-lf.s3.ap-south-1.amazonaws.com/static/machines/Sulzer+Loom.webp',
    text: 'Sulzer Loom',
  },
  {
    img: 'https://miscellaneous-lf.s3.ap-south-1.amazonaws.com/static/machines/Auto+loom.webp',
    text: 'Auto Loom',
  },
  {
    img: 'https://miscellaneous-lf.s3.ap-south-1.amazonaws.com/static/machines/Airjet+Loom.webp',
    text: 'Airjet Loom',
  },
  {
    img: 'https://miscellaneous-lf.s3.ap-south-1.amazonaws.com/static/machines/Jacquard+Loom.webp',
    text: 'Jacquard Loom',
  },
];

export const LOCOFAST_ASSURED_MACHINE_IMAGES_DATA = [
  {
    img: 'https://miscellaneous-lf.s3.ap-south-1.amazonaws.com/static/machines/Jet+dyeing+machine.webp',
    text: 'Jet dyeing machine',
  },
  {
    img: 'https://miscellaneous-lf.s3.ap-south-1.amazonaws.com/static/machines/Soft+flow+dyeing+machine.webp',
    text: 'Soft flow dyeing machine',
  },
  {
    img: 'https://miscellaneous-lf.s3.ap-south-1.amazonaws.com/static/machines/Rotary+printing+machine.webp',
    text: 'Rotary printing machine',
  },
  {
    img: 'https://miscellaneous-lf.s3.ap-south-1.amazonaws.com/static/machines/Soaper+machine.webp',
    text: 'Soaper machine',
  },
];

export const DATE_TIME = {
  MMMDDYYYY: 'MMM DD, YYYY',
};

export const RECENT_SEARCHES_REDIRECTION_STEPS = {
  HIT_FABRIC_API: 1,
  HIT_ORDER_API: 2,
};

export const REQUIREMENT_TYPE: { [char: number]: string } = {
  1: 'Greige',
  2: 'Yarn Dyed',
  3: 'RFD',
  4: 'Dyed',
  5: 'Printed',
  6:  'Denim',
};

export const FABRIC_PRODUCTION_TYPE = {
  GREIGE: 1,
  FINISHED_FABRIC: 2,
  DENIM: 6,
};

export const DENIM_WEIGHTS = {
  afterWash : 'AW',
  beforeWash : 'BW',
}

export const YARDAGE_DETAILS = 'Yardage details'
export const BULK_SAMPLE = 'Bulk Sample'

export const DELIVERY_TYPE: { [char: number]: string } = {
  1: 'Landed',
  2: 'Self pickup',
  3: 'CNF'
};

export const DELIVERY_TYPE_KEY = {
  Landed: 1,
  Self_pickup: 2,
  CNF: 3,
};

export const DENIM_COTTON = '100% Cotton';
export const DENIM_BLENDS = 'Blends';
export const DENIM_SAMPLE = 'Denim';
export const YARDAGE_SAMPLE = 'Yardage';
export const DENIM_COTTON_LABEL = '100% Cotton';
export const DENIM_BLENDS_LABEL = 'Denim Blends';

export const TAB_NAME = {
  HOME: 'Home',
  ORDER: 'order',
  DISPATCH: 'dispatch',
  RAISED_ISSUE: 'raised_issue',
  IMAGE_VIEWER: 'image_viewer',
  SAMPLE_DETAILS: 'sample_details',
  BULK_SAMPLE_DETAILS: 'bulk_sample_details',
  APPROVE_BULK_SAMPLE: 'approve_bulk_sample',
  LOCOFAST_ASSURED_BOTTOMSHEET: 'locofast_assured_bottomsheet',
  LOCOFAST_QUALITY_QUARANTEE_BOTTOMSHEET: 'locofast_quality_guarantee_bottomsheet',
  LOCOFAST_QUALITY_QUARANTEE_JOB_WORK_BOTTOMSHEET:
    'locofast_quality_guarantee_job_work_bottomsheet',
};

export const TNA_STEP_STATUS = {
  PENDING: 1,
  IN_PROGRESS: 2,
  COMPLETED: 3,
  CANCELLED: 4,
  TERMINATED: 5,
  PROVISIONAL: 6,
};

export const SAMPLE_TYPES = {
  LAB_DIP: 1,
  STRIKE_OFF: 2,
  BULK: 3,
  BIT_LOOM: 4,
};

export const SAMPLE_STATUSES = {
  NOT_INITIATED: 0,
  DISPATCHED: 1,
  RESUBMISSION_REQUESTED: 2,
  CANCELLED: 3,
  APPROVED: 4,
};

export const STATUS = {
  COMPLETED: 'completed',
  PENDING: 'pending',
  IN_PROGRESS: 'in-progress',
  TERMINATED: 'terminated',
  CANCELLED: 'cancelled',
  AWAITING: 'awaiting',
};
export const GST_FIELD_STATE = {
  Verify: 'Verify',
  Loading: 'Loading',
  Error: 'Error',
  Valid: 'Valid',
};
export const IS_NEW_CUSTOMER = 1;
export const email = 'mail@locofast.com';
export const GST_NUMBER_LENGTH = 15;
export const PINCODE_LENGTH = 6;

export const DOCUMENT = {
  PURCHASE_ORDER: 'Purchase Order',
  ADVANCE_RECEIPT: 'Advance Receipt',
  ORDER_SUMMARY: 'Customer Order Summary',
  PROFORMA_INVOICE:'Proforma Invoice',
  ORDER_ISSUE :'Order Issue'
};

export const MIN_EPI_PPI_VALUE = 20;
export const MAX_EPI_PPI_VALUE = 200;
export const MIN_WEIGHT = 40;
export const MAX_WEIGHT = 800;
export const MAX_WIDTH = 200;
export const MIN_WIDTH = 20;
export const MAX_THREAD_COUNT_LENGTH = 4;
export const TRUNCATE_LENGTH = 10;
export const TRUNCATE_LENGTH_12 = 12;

export const EMPTY_INPUT = '--';

export const HEADER_IGNORE_401 = 'x-ignore-401';
export const HEADER_IGNORE_403 = 'x-ignore-403';

export const QUOTE_TAB = {
  ALL_OPEN: '1',
  RECEIVED: '2',
  NOT_RECEIVED: '3',
  CLOSED: '4',
};

export const ORDER_TAB = {
  PRODUCTION: '10',
  IN_TRANSIT: '20',
  DELIVERED: '40',
};

export const HTTP_STATUS = {
  CONTINUE: 100,
  SWITCHING_PROTOCOLS: 101,
  PROCESSING: 102,
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NON_AUTHORITATIVE_INFORMATION: 203,
  NO_CONTENT: 204,
  RESET_CONTENT: 205,
  PARTIAL_CONTENT: 206,
  AMBIGUOUS: 300,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  SEE_OTHER: 303,
  NOT_MODIFIED: 304,
  TEMPORARY_REDIRECT: 307,
  PERMANENT_REDIRECT: 308,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  PROXY_AUTHENTICATION_REQUIRED: 407,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  GONE: 410,
  LENGTH_REQUIRED: 411,
  PRECONDITION_FAILED: 412,
  PAYLOAD_TOO_LARGE: 413,
  URI_TOO_LONG: 414,
  UNSUPPORTED_MEDIA_TYPE: 415,
  REQUESTED_RANGE_NOT_SATISFIABLE: 416,
  EXPECTATION_FAILED: 417,
  I_AM_A_TEAPOT: 418,
  UNPROCESSABLE_ENTITY: 422,
  FAILED_DEPENDENCY: 424,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
};
export const LOOM_TYPE: { [char: number]: string } = {
  1: 'Power Loom',
  2: 'Sulzer',
  3: 'Air Jet',
  4: 'Water Jet'
};

export const REQUEST_CATEGORY = {
  COTTON: 1,
  RAYON: 2,
  DENIM: 3,
  POLYESTER: 4,
};

export const PROCESSING_PRODUCTIONSUBTYPE = [3, 4, 5];

export const PRODUCTIONSUPTYPE_TO_LABEL_MAPPING: { [char: number]: string } = {
  3: 'Finishing type',
  4: 'Color intensity',
  5: 'Printing method',
};

export const GST_NUMBER_REGEX = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][1-9A-Z]{2}[0-9A-Z]$/;
export const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;
export const UPTO_TWO_DECIMAL_REGEX = /^\d+(\.\d{1,2})?$/;
export const MAX_ORDER_QUANTITY = 1000000;
export const MIN_ORDER_QUANTITY_TOP_SELLING = 1000;

export const ORDERS_STATUS = {
  PROVISIONAL: 5,
  CREATED: 10,
  DISPATCHED: 20,
  WAREHOUSE: 30,
  DELIVERED: 40,
  CANCELLED: 50,
};

export const TNA_ATTRIBUTE_CATEGORY = {
  ORDER_PLACED: 1,
  MATERIAL_INWARDS: 2,
  SAMPLING: 3,
  GOODS_READY: 4,
  FINISHING_AND_PACKING: 5,
  DISPATCH: 6,
};

export const QUOTE_TABS = [
  //can be used in future
  // {
  //   tabId: '1',
  //   tabName: 'All open',
  // },
  {
    tabId: '2',
    tabName: 'Quotes received',
  },
  {
    tabId: '3',
    tabName: 'Quotes not received',
  },
  {
    tabId: '4',
    tabName: 'Closed',
  },
];

export const REQUEST_TAB = {
  ALL_OPEN: 1,
  RECEIVED: 2,
  NOT_RECEIVED: 3,
  CLOSED: 4,
};

export const ORDER_TABS = [
  {
    tabId: '10',
    tabName: 'Production',
  },
  {
    tabId: '20',
    tabName: 'In transit',
  },
  {
    tabId: '40',
    tabName: 'Delivered',
  },
];

export const EMPTY_ORDER_PAGE_SCREEN_DATA = {
  [ORDER_TAB.PRODUCTION]: {
    logo: ordersNoQuoteLogo,
    dispText: 'Nothing in Production!',
    desc: 'You don\'t have any order in production currently',
  },
  [ORDER_TAB.IN_TRANSIT]: {
    logo: intransitNoQuoteLogo,
    dispText: 'Nothing in-transit!',
    desc: 'You don\'t have any in-transit order currently',
  },
  [ORDER_TAB.DELIVERED]: {
    logo: fabricRoll,
    dispText: 'Nothing delivered!',
    desc: 'You don\'t have any order delivered currently',
  },
};

export const NAVIGATION_TABS = {
  ADD_QUERY: 0,
  HOME: 1,
  QUERIES: 2,
  ORDERS: 3,
  PAYMENTS: 4,
};

export const IS_SWITCH_TO_CASH_MODAL_VISIBLE = false;

export const REFERENCE_CODE_ACTIVITY = {
  ORDER_CONFIRMATION: 1,
  FINAL_DSIPATCH: 2,
  BULK_APPROVAL: 3,
};

export const MENU_ITEM_KEY = {
  TOTAL_FABRIC_COST_KEY: '1',
  LOGISTIC_COST_KEY: '2',
  GST_KEY: '3',
};
export const APP_LINK_EVENTS = {
  invalidSecret: 'InvalidSecret',
  Unauthorized: 'Unauthorized',
  Success: 'Success',
  Back: 'Back',
  Home: 'Home',
  PageLoadFinished: 'PageLoadFinished',
};

export const RAISE_ISSUE_STATUS = {
  NO_ACTION: 0,
  NO_ISSUE_RAISED: 1,
  ISSUE_RAISED: 2,
  NOT_ELIGIBLE_FOR_RETURN: 3,
};

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong !';

export const YARN_TYPE = {
  CARDED: 1,
  COMBED: 2,
  COMPACT: 3,
  OPEN_END: 4,
};
export const YARN_TYPE_REVERSE = {
  [YARN_TYPE.CARDED]: 'Carded',
  [YARN_TYPE.COMBED]: 'Combed',
  [YARN_TYPE.COMPACT]: 'Compact',
  [YARN_TYPE.OPEN_END]: 'Open-end',
};
export const CLOSE_REQUEST_OPTIONS = (isQuoteReceived: boolean) => {
  if (isQuoteReceived) {
    return [
      { text: 'Incorrect quality requested', showWarning: true },
      { text: 'Quote prices are unsatisfactory', showWarning: true },
      { text: 'Cheaper rates available elsewhere', showWarning: true },
      { text: 'Fabric is no longer required', showWarning: true },
      { text: 'Need an early dispatch ', showWarning: true },
      { text: 'Low supplier rating', showWarning: true },
    ];
  }

  return [{ text: 'Incorrect quality requested', showWarning: true  }, { text: 'Fabric is no longer required', showWarning: true }];
};
export const IS_SHOWING_COMMENTS_TO_CUSTOMER = true;

export const LOCOFAST_ASSURED = 'LOCOFAST_ASSURED';
export const QUALITY_GUARANTEE = 'QUALITY_GUARANTEE';

export const ORDER_QUALITY_DATA = {
  LOCOFAST_ASSURED: {
    backgroundColor: '#C6F8DB',
    textMessage: 'Assured',
    textColor: '#0067E2',
    icon: LocofastAssuredIcon,
  },
  QUALITY_GUARANTEE: {
    backgroundColor: '#F9E6FF',
    textMessage: 'Quality Guarantee',
    textColor: '#3944B4',
    icon: QualityGuaranteeThumsUp,
  },
};

export const QUALITY_GUARANTED_TRUE = 1;
export const LOCOFAST_ASSURED_TRUE = 1;

export const WHATSAPP_REDIRECTION = process.env.REACT_APP_WHATSAPP_URL;

export const YARD_METER_CONVERSION_FACTOR = 0.9144;

export const MIN_CARD_WIDTH = 190;

export const METER_UNIT_VALUE = 2;

export const MAX_CHARACTER_LIMIT = 500;

export const MSME_STATUS: { [char: string]: number | null } = {
  NOT_AVAILABLE: null,
  VERIFIED: 1,
  NOT_VERIFIED: 2,
};

export const MB_10 = 10485760; // 10485760 Bytes

export const MAXIMUM_ADDRESS_LIMIT = 50;

export const PACKAGING_FILE_TITLE = 'Direct Invoicing Packing List';

export const FINAL_DSIPATCH_LABEL = 'Final dispatch';


export const FACTORS = {
  METER_TO_YARD: 1.09361,
  KILOGRAM_TO_POUND: 2.20462,
};
export const conversion=(unitName:string)=>{
  if(unitName==='yd'){
    return FACTORS.METER_TO_YARD;
  }
  else if(unitName==='lb'){
    return FACTORS.KILOGRAM_TO_POUND
  }
  return 1;
}
export const SYMBOL={
  DOLLAR: '$',
  RUPEE: '₹'
}

export const UNICODE={
  'India': '91',
  'Bangladesh': '880',
}

export const UNIT_SHORTNAME={
  METER: 'm',
  YARD: 'yd',
}

export const MAX_COMPOSITION_PERCENTAGE = 51;

export const DELHI_NCR_CITIES = [
  'ghaziabad',
  'delhi',
  'gurgaon',
  'faridabad',
  'greater noida',
  'meerut',
  'noida',
];
export const DELHI_NCR = 'Delhi NCR';
export const WHATSAPP_NUMBER_STATUS = {
  INVALID_NUMBER: '20',
  NOT_OPT_IN: '40',
  SOFT_VALID:'80',
  VALID: '100',
};