import actions from '../actions';
import {
  FETCH_DOWNLOAD_PACKING_LIST_FAILURE,
  FETCH_DOWNLOAD_PACKING_LIST_REQUEST,
  FETCH_DOWNLOAD_PACKING_LIST_RESET,
  FETCH_DOWNLOAD_PACKING_LIST_SUCCESS,
} from '../constants';

const initialState = {
  downloadPackingListRequest: false,
  downloadPackingListData: {},
  downloadPackingListStatus: false,
};

interface stateType {
  downloadPackingListRequest: boolean;
  downloadPackingListData: any;
  downloadPackingListStatus: boolean;
}

interface actionType {
  type: string;
  payload: {};
}

export const downloadPackingListReducer = (state = initialState, action: actionType): stateType => {
  switch (action.type) {
    case FETCH_DOWNLOAD_PACKING_LIST_REQUEST:
      return {
        ...state,
        downloadPackingListRequest: true,
        downloadPackingListData: {},
        downloadPackingListStatus: false,
      };
    case FETCH_DOWNLOAD_PACKING_LIST_SUCCESS:
      return {
        ...state,
        downloadPackingListRequest: false,
        downloadPackingListData: action.payload,
        downloadPackingListStatus: true,
      };
    case FETCH_DOWNLOAD_PACKING_LIST_FAILURE:
      return {
        ...state,
        downloadPackingListRequest: false,
        downloadPackingListData: action.payload,
        downloadPackingListStatus: false,
      };
    case FETCH_DOWNLOAD_PACKING_LIST_RESET:
      return {
        ...state,
        downloadPackingListRequest: false,
        downloadPackingListData: {},
        downloadPackingListStatus: false,
      };
    default:
      return {
        ...state,
      };
  }
};
