// Auth
export const FETCH_OTP_REQUEST = 'FETCH_OTP_REQUEST';
export const FETCH_OTP_SUCCESS = 'FETCH_OTP_SUCCESS';
export const FETCH_OTP_FAILURE = 'FETCH_OTP_FAILURE';
export const FETCH_OTP_RESET = 'FETCH_OTP_RESET';
export const FETCH_EDIT_NUMBER = 'FETCH_EDIT_NUMBER';

// Home
export const FETCH_METRICS_REQUEST = 'FETCH_METRICS_REQUEST';
export const FETCH_METRICS_SUCCESS = 'FETCH_METRICS_SUCCESS';
export const FETCH_METRICS_FAILURE = 'FETCH_METRICS_FAILURE';

// Login
export const CHECK_OTP_REQUEST = 'CHECK_OTP_REQUEST';
export const CHECK_OTP_SUCCESS = 'CHECK_OTP_SUCCESS';
export const CHECK_OTP_FAILURE = 'CHECK_OTP_FAILURE';
export const CHECK_OTP_RESET = 'CHECK_OTP_RESET';
export const CHECK_OTP_NEW_CUSTOMER = 'CHECK_OTP_NEW_CUSTOMER';
export const APP_LINK_VERIFIED = 'APP_LINK_VERIFIED';

// Logout
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

// View quotes
export const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';
export const FETCH_DATA_RESET = 'FETCH_DATA_RESET';

// Raise Issue Detail
export const FETCH_RAISE_ISSUE_DATA_REQUEST = 'FETCH_RAISE_ISSUE_DATA_REQUEST';
export const FETCH_RAISE_ISSUE_DATA_SUCCESS = 'FETCH_RAISE_ISSUE_DATA_SUCCESS';
export const FETCH_RAISE_ISSUE_DATA_FAILURE = 'FETCH_RAISE_ISSUE_DATA_FAILURE';
export const FETCH_RAISE_ISSUE_DATA_RESET = 'FETCH_RAISE_ISSUE_DATA_RESET';

// QuoteDetails
export const FETCH_QUOTE_DETAILS_REQUEST = 'FETCH_QUOTE_DETAILS_REQUEST';
export const FETCH_QUOTE_DETAILS_SUCCESS = 'FETCH_QUOTE_DETAILS_SUCCESS';
export const FETCH_QUOTE_DETAILS_FAILURE = 'FETCH_QUOTE_DETAILS_FAILURE';

//s3 Media Upload
export const FETCH_UPLOAD_MEDIA_REQUEST = 'FETCH_UPLOAD_MEDIA_REQUEST';
export const FETCH_UPLOAD_MEDIA_SUCCESS = 'FETCH_UPLOAD_MEDIA_SUCCESS';
export const FETCH_UPLOAD_MEDIA_FAILURE = 'FETCH_UPLOAD_MEDIA_FAILURE';

//  Additional QuoteDetails
export const FETCH_ADDITIONAL_QUOTE_DETAILS_REQUEST = 'FETCH_ADDITIONAL_QUOTE_DETAILS_REQUEST';
export const FETCH_ADDITIONAL_QUOTE_DETAILS_SUCCESS = 'FETCH_ADDITIONAL_QUOTE_DETAILS_SUCCESS';
export const FETCH_ADDITIONAL_QUOTE_DETAILS_FAILURE = 'FETCH_ADDITIONAL_QUOTE_DETAILS_FAILURE';

// Order
export const FETCH_ORDER_REQUEST = 'FETCH_ORDER_REQUEST';
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_FAILURE = 'FETCH_ORDER_FAILURE';

// Profile
export const FETCH_CUSTOMER_REQUEST = 'FETCH_CUSTOMER_REQUEST';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_FAILURE = 'FETCH_CUSTOMER_FAILURE';
export const CUSTOMER_DATA_CLEAR =  'CUSTOMER_DATA_CLEAR';

// Intransit Order
export const FETCH_INTRANSIT_ORDER_REQUEST = 'FETCH_INTRANSIT_ORDER_REQUEST';
export const FETCH_DELIVERED_ORDER_SUCCESS = 'FETCH_DELIVERED_ORDER_SUCCESS';
export const FETCH_INTRANSIT_ORDER_SUCCESS = 'FETCH_INTRANSIT_ORDER_SUCCESS';
export const FETCH_INTRANSIT_ORDER_FAILURE = 'FETCH_INTRANSIT_ORDER_FAILURE';

// Fabric Issues
export const FETCH_FABRIC_ISSUES_REQUEST = 'FETCH_FABRIC_ISSUES_REQUEST';
export const FETCH_FABRIC_ISSUES_SUCCESS = 'FETCH_FABRIC_ISSUES_SUCCESS';
export const FETCH_FABRIC_ISSUES_FAILURE = 'FETCH_FABRIC_ISSUES_FAILURE';

// Raise Issue
export const RAISE_FABRIC_ISSUE_REQUEST = 'RAISE_FABRIC_ISSUE_REQUEST';
export const RAISE_FABRIC_ISSUE_SUCCESS = 'RAISE_FABRIC_ISSUE_SUCCESS';
export const RAISE_FABRIC_ISSUE_FAILURE = 'RAISE_FABRIC_ISSUE_FAILURE';

// Order TNA
export const FETCH_ORDER_TNA_REQUEST = 'FETCH_ORDER_TNA_REQUEST';
export const FETCH_ORDER_TNA_RESET = 'FETCH_ORDER_TNA_RESET';
export const FETCH_ORDER_TNA_SUCCESS = 'FETCH_ORDER_TNA_SUCCESS';
export const FETCH_ORDER_TNA_FAILURE = 'FETCH_ORDER_TNA_FAILURE';

// Production Order
export const FETCH_PRODUCTION_ORDER_REQUEST = 'FETCH_PRODUCTION_ORDER_REQUEST';
export const FETCH_PRODUCTION_ORDER_SUCCESS = 'FETCH_PRODUCTION_ORDER_SUCCESS';
export const FETCH_PRODUCTION_ORDER_FAILURE = 'FETCH_PRODUCTION_ORDER_FAILURE';
export const FETCH_PRODUCTION_ORDER_RESET = 'FETCH_PRODUCTION_ORDER_RESET';

// Production Detail
export const FETCH_PRODUCTION_DETAILS_REQUEST = 'FETCH_PRODUCTION_DETAILS_REQUEST';
export const FETCH_PRODUCTION_DETAILS_SUCCESS = 'FETCH_PRODUCTION_DETAILS_SUCCESS';
export const FETCH_PRODUCTION_DETAILS_RESET = 'FETCH_PRODUCTION_DETAILS_RESET';
export const FETCH_PRODUCTION_DETAILS_FAILURE = 'FETCH_PRODUCTION_DETAILS_FAILURE';

//gstVerification
export const FETCH_GST_NUMBER_REQUEST = 'FETCH_GST_NUMBER_REQUEST';
export const FETCH_GST_NUMBER_SUCCESS = 'FETCH_GST_NUMBER_SUCCESS';
export const FETCH_GST_NUMBER_FAILURE = 'FETCH_GST_NUMBER_FAILURE';
export const GST_NUBER_RESET = 'GST_NUBER_RESET';

//customerdetailsviagst
export const FETCH_CUSTOMER_DETAILS_REQUEST = 'FETCH_CUSTOMER_DETAILS_REQUEST';
export const FETCH_CUSTOMER_DETAILS_SUCCESS = 'FETCH_CUSTOMER_DETAILS_SUCCESS';
export const FETCH_CUSTOMER_DETAILS_FAILURE = 'FETCH_CUSTOMER_DETAILS_FAILURE';
// Create Request
export const CREATE_REQUEST_REQUEST = 'CREATE_REQUEST_REQUEST';
export const CREATE_REQUEST_SUCCESS = 'CREATE_REQUEST_SUCCESS';
export const CREATE_REQUEST_FAILURE = 'CREATE_REQUEST_FAILURE';

// Customer Feedback section
export const CUSTOMER_FEEDBACK_REQUEST = 'CUSTOMER_FEEDBACK_REQUEST';
export const CUSTOMER_FEEDBACK_SUCCESS = 'CUSTOMER_FEEDBACK_SUCCESS';
export const CUSTOMER_FEEDBACK_FAILURE = 'CUSTOMER_FEEDBACK_FAILURE';
export const CUSTOMER_FEEDBACK_RESET = 'CUSTOMER_FEEDBACK_RESET';

// customer feedback given
export const CREATE_CUSTOMER_FEEDBACK_REQUEST = 'CREATE_CUSTOMER_FEEDBACK_REQUEST';
export const CREATE_CUSTOMER_FEEDBACK_SUCCCESS = 'CREATE_CUSTOMER_FEEDBACK_SUCCESS';
export const CREATE_CUSTOMER_FEEDBACK_FAILURE = 'CREATE_CUSTOMER_FEEDBACK_FAILURE';

export const FETCH_PREVIOUS_ORDERS_REQUEST = 'FETCH_PREVIOUS_ORDERS_REQUEST';
export const FETCH_PREVIOUS_ORDERS_SUCCESS = 'FETCH_PREVIOUS_ORDERS_SUCCESS';
export const FETCH_PREVIOUS_ORDERS_FAILURE = 'FETCH_PREVIOUS_ORDERS_FAILURE';

// Customer Material Data
export const FETCH_QUERY_MATERIAL_REQUEST = 'FETCH_QUERY_MATERIAL_REQUEST';
export const FETCH_QUERY_MATERIAL_SUCCESS = 'FETCH_QUERY_MATERIAL_SUCCESS';
export const FETCH_QUERY_MATERIAL_FAILURE = 'FETCH_QUERY_MATERIAL_FAILURE';

// Location Details
export const GET_LOCATION_REQUEST = 'GET_LOCATION_REQUEST';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_FAILURE = 'GET_LOCATION_FAILURE';
export const GET_LOCATION_CLEAR = 'GET_LOCATION_CLEAR';

// Fetch Customer All Address
export const GET_ADDRESS_REQUEST = 'GET_ADDRESS_REQUEST';
export const GET_ADDRESS_SUCCESS = 'GET_ADDRESS_SUCCESS';
export const GET_ADDRESS_FAILURE = 'GET_ADDRESS_FAILURE';

// Update Customer Address
export const UPDATE_ADDRESS_REQUEST = 'UPDATE_ADDRESS_REQUEST';
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
export const UPDATE_ADDRESS_RESET = 'UPDATE_ADDRESS_RESET';
export const UPDATE_ADDRESS_FAILURE = 'UPDATE_ADDRESS_FAILURE';

// Add Customer Address
export const ADD_ADDRESS_REQUEST = 'ADD_ADDRESS_REQUEST';
export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
export const ADD_ADDRESS_RESET = 'ADD_ADDRESS_RESET';
export const ADD_ADDRESS_FAILURE = 'ADD_ADDRESS_FAILURE';

// Get logistic Address
export const GET_LOGISTIC_COST_REQUEST = 'GET_LOGISTIC_COST_REQUEST';
export const GET_LOGISTIC_COST_SUCCESS = 'GET_LOGISTIC_COST_SUCCESS';
export const GET_LOGISTIC_COST_FAILURE = 'GET_LOGISTIC_COST_FAILURE';

// Add GST Detail
export const FETCH_ADD_GST_REQUEST = 'FETCH_ADD_GST_REQUEST';
export const FETCH_ADD_GST_RESET = 'FETCH_ADD_GST_RESET';
export const FETCH_ADD_GST_SUCCESS = 'FETCH_ADD_GST_SUCCESS';
export const FETCH_ADD_GST_FAILURE = 'FETCH_ADD_GST_FAILURE';

// Create Provisional Order
export const CREATE_PROVISIONAL_ORDER_REQUEST = 'CREATE_PROVISIONAL_ORDER_REQUEST';
export const CREATE_PROVISIONAL_ORDER_SUCCESS = 'CREATE_PROVISIONAL_ORDER_SUCCESS';
export const CREATE_PROVISIONAL_ORDER_FAILURE = 'CREATE_PROVISIONAL_ORDER_FAILURE';

// Update reference number
export const UPDATE_REFERENCE_NUMBER_REQUEST = 'UPDATE_REFERENCE_NUMBER_REQUEST';
export const UPDATE_REFERENCE_NUMBER_SUCCESS = 'UPDATE_REFERENCE_NUMBER_SUCCESS';
export const UPDATE_REFERENCE_NUMBER_FAILURE = 'UPDATE_REFERENCE_NUMBER_FAILURE';

// Get order data
export const GET_PROVISIONAL_ORDER_REQUEST = 'GET_PROVISIONAL_ORDER_REQUEST';
export const GET_PROVISIONAL_ORDER_SUCCESS = 'GET_PROVISIONAL_ORDER_SUCCESS';
export const GET_PROVISIONAL_ORDER_FAILURE = 'GET_PROVISIONAL_ORDER_FAILURE';

// Create Razorpay Order
export const CREATE_RAZORPAY_ORDER_REQUEST = 'CREATE_RAZORPAY_ORDER_REQUEST';
export const CREATE_RAZORPAY_ORDER_SUCCESS = 'CREATE_RAZORPAY_ORDER_SUCCESS';
export const CREATE_RAZORPAY_ORDER_FAILURE = 'CREATE_RAZORPAY_ORDER_FAILURE';

// RazorPay Payment Status
export const CHECK_RAZORPAY_PAYMENT_REQUEST = 'CHECK_RAZORPAY_PAYMENT_REQUEST';
export const CHECK_RAZORPAY_PAYMENT_SUCCESS = 'CHECK_RAZORPAY_PAYMENT_SUCCESS';
export const CHECK_RAZORPAY_PAYMENT_FAILURE = 'CHECK_RAZORPAY_PAYMENT_FAILURE';

// Cancle Order
export const CANCEL_ORDER_REQUEST = 'CANCEL_ORDER_REQUEST';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_FAILURE = 'CANCEL_ORDER_FAILURE';

// Fetch PaymentDetails
export const FETCH_PAYMENT_DETAILS_REQUEST = 'FETCH_PAYMENT_DETAILS_REQUEST';
export const FETCH_PAYMENT_DETAILS_SUCCESS = 'FETCH_PAYMENT_DETAILS_SUCCESS';
export const FETCH_PAYMENT_DETAILS_FAILURE = 'FETCH_PAYMENT_DETAILS_FAILURE';

// Fetch ReturnAndRefundPolicy
export const FETCH_RETURN_AND_REFUND_POLICY_REQUEST = 'FETCH_RETURN_AND_REFUND_POLICY_REQUEST';
export const FETCH_RETURN_AND_REFUND_POLICY_SUCCESS = 'FETCH_RETURN_AND_REFUND_POLICY_SUCCESS';
export const FETCH_RETURN_AND_REFUND_POLICY_FAILURE = 'FETCH_RETURN_AND_REFUND_POLICY_FAILURE';

// Add sample approval 
export const ADD_SAMPLE_APPROVAL_REQUEST = 'ADD_SAMPLE_APPROVAL_REQUEST'; 
export const ADD_SAMPLE_APPROVAL_SUCCESS = 'ADD_SAMPLE_APPROVAL_SUCCESS'; 
export const ADD_SAMPLE_APPROVAL_FAILURE = 'ADD_SAMPLE_APPROVAL_FAILURE'; 

// Fetch Customer VPA
export const FETCH_CUSTOMER_VPA_REQUEST = 'FETCH_CUSTOMER_VPA_REQUEST';
export const FETCH_CUSTOMER_VPA_SUCCESS = 'FETCH_CUSTOMER_VPA_SUCCESS';
export const FETCH_CUSTOMER_VPA_FAILURE = 'FETCH_CUSTOMER_VPA_FAILURE';

// Fetch Search Result
export const FETCH_SEARCH_RESULT_REQUEST = 'FETCH_SEARCH_RESULT_REQUEST';
export const FETCH_SEARCH_RESULT_SUCCESS = 'FETCH_SEARCH_RESULT_SUCCESS';
export const FETCH_SEARCH_RESULT_FAILURE = 'FETCH_SEARCH_RESULT_FAILURE';

// Notify Api
export const CUSTOMER_NOTIFY_REQUEST = 'CUSTOMER_NOTIFY_REQUEST';
export const CUSTOMER_NOTIFY_SUCCESS = 'CUSTOMER_NOTIFY_SUCCESS';
export const CUSTOMER_NOTIFY_FAILURE = 'CUSTOMER_NOTIFY_FAILURE';
//Status Codes
export const STATUS_CODE = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  INTERNAL_SERVER_ERROR: 500,
};

export const SIGNUP_TOAST = 'SIGNUP_TOAST';

//Top Selling data
export const FETCH_TOP_QUALITY_REQUEST = 'FETCH_TOP_QUALITY_REQUEST';
export const FETCH_TOP_QUALITY_SUCCESS = 'FETCH_TOP_QUALITY_SUCCESS';
export const FETCH_INVENTORY_SALE_SUCCESS = 'FETCH_INVENTORY_SALE_SUCCESS';
export const FETCH_TOP_QUALITY_FAILURE = 'FETCH_TOP_QUALITY_FAILURE';
export const FETCH_TOP_QUALITY_RESET = 'FETCH_TOP_QUALITY_RESET';

//Live Rates Data
export const FETCH_LIVE_RATES_REQUEST = 'FETCH_LIVE_RATES_REQUEST';
export const FETCH_LIVE_RATES_SUCCESS = 'FETCH_LIVE_RATES_SUCCESS';
export const FETCH_LIVE_RATES_FAILURE = 'FETCH_LIVE_RATES_FAILURE';
export const FETCH_LIVE_RATES_RESET = 'FETCH_LIVE_RATES_RESET';

//Live Rates Filtered Data
export const FETCH_FILTERED_LIVE_RATES_REQUEST = 'FETCH_FILTERED_LIVE_RATES_REQUEST';
export const FETCH_FILTERED_LIVE_RATES_SUCCESS = 'FETCH_FILTERED_LIVE_RATES_SUCCESS';
export const FETCH_FILTERED_LIVE_RATES_FAILURE = 'FETCH_FILTERED_LIVE_RATES_FAILURE';
export const FETCH_FILTERED_LIVE_RATES_RESET = 'FETCH_FILTERED_LIVE_RATES_RESET';

// update Credit Period
export const UPDATE_CREDIT_PERIOD_REQUEST = 'UPDATE_CREDIT_PERIOD_REQUEST';
export const UPDATE_CREDIT_PERIOD_SUCCESS = 'UPDATE_CREDIT_PERIOD_SUCCESS';
export const UPDATE_CREDIT_PERIOD_FAILURE = 'UPDATE_CREDIT_PERIOD_FAILURE';

// close Query
export const CLOSE_QUERY_REQUEST = 'CLOSE_QUERY_REQUEST';
export const CLOSE_QUERY_SUCCESS = 'CLOSE_QUERY_SUCCESS';
export const CLOSE_QUERY_FAILURE = 'CLOSE_QUERY_FAILURE';
// Reference code Api
export const APPROVAL_REFERENCE_CODE_REQUEST = 'APPROVAL_REFERENCE_CODE_REQUEST';
export const APPROVAL_REFERENCE_CODE_SUCCCESS = 'APPROVAL_REFERENCE_CODE_SUCCCESS';
export const APPROVAL_REFERENCE_CODE_FAILURE = 'APPROVAL_REFERENCE_CODE_FAILURE';

// Verify Reference Api
export const VERIFY_REFERENCE_CODE_REQUEST = 'VERIFY_REFERENCE_CODE_REQUEST';
export const VERIFY_REFERENCE_CODE_SUCCCESS = 'VERIFY_REFERENCE_CODE_SUCCCESS';
export const VERIFY_REFERENCE_CODE_FAILURE = 'VERIFY_REFERENCE_CODE_FAILURE';

// Order Validate api
export const FETCH_ORDER_VALIDATE_REQUEST = 'FETCH_ORDER_VALIDATE_REQUEST';
export const FETCH_ORDER_VALIDATE_SUCCESS = 'FETCH_ORDER_VALIDATE_SUCCESS';
export const FETCH_ORDER_VALIDATE_FAILURE = 'FETCH_ORDER_VALIDATE_FAILURE';
export const FETCH_ORDER_VALIDATE_RESET = 'FETCH_ORDER_VALIDATE_RESET';

// Set Tab
export const SET_QUOTES_TAB = 'SET_QUOTES_TAB';
export const SET_ORDERS_TAB = 'SET_ORDERS_TAB';

// Set Tab scrollposition
export const SET_QUOTES_TAB_SCROLL_OFFSET = 'SET_QUOTES_TAB_SCROLL_OFFSET';
export const SET_ORDERS_TAB_SCROLL_OFFSET = 'SET_ORDERS_TAB_SCROLL_OFFSET';
// Get currency conversion rate
export const GET_CURRENCY_CONVERSION_REQUEST = 'GET_CURRENCY_CONVERSION_REQUEST';
export const GET_CURRENCY_CONVERSION_SUCCESS = 'GET_CURRENCY_CONVERSION_SUCCESS';
export const GET_CURRENCY_CONVERSION_FAILURE = 'GET_CURRENCY_CONVERSION_FAILURE';

// MSME DETAILS

export const UPDATE_MSME_DETAILS_REQUEST = 'UPDATE_MSME_DETAILS_REQUEST';
export const UPDATE_MSME_DETAILS_SUCCESS = 'UPDATE_MSME_DETAILS_SUCCESS';
export const UPDATE_MSME_DETAILS_FAILURE = 'UPDATE_MSME_DETAILS_FAILURE';
export const UPDATE_MSME_DETAILS_RESET = 'UPDATE_MSME_DETAILS_RESET';
export const SHOW_MSME_FLOW = 'SHOW_MSME_FLOW';

//Get Cities on the basis of Countries
export const GET_CITIES_REQUEST='GET_CITIES_REQUEST';
export const GET_CITIES_SUCCESS='GET_CITIES_SUCCESS';
export const GET_CITIES_FAILURE='GET_CITIES_FAILURE';

//Get Banks 
export const GET_BANKS_SUCCESS='GET_BANKS_SUCCESS';
export const GET_BANKS_REQUEST='GET_BANKS_REQUEST';
export const GET_BANKS_FAILURE='GET_BANKS_FAILURE';



//Whatsapp
export const CHECK_WHATSAPP_OTP_REQUEST = 'CHECK_WHATSAPP_OTP_REQUEST';
export const CHECK_WHATSAPP_OTP_SUCCESS = 'CHECK_WHATSAPP_OTP_SUCCESS';
export const CHECK_WHATSAPP_OTP_FAILURE = 'CHECK_WHATSAPP_OTP_FAILURE';
export const CHECK_WHATSAPP_OTP_RESET = 'CHECK_WHATSAPP_OTP_RESET';
export const FETCH_WHATSAPP_OTP_REQUEST='FETCH_WHATSAPP_OTP_REQUEST';
export const FETCH_WHATSAPP_OTP_FAILURE='FETCH_WHATSAPP_OTP_FAILURE';
export const FETCH_WHATSAPP_OTP_RESET='FETCH_WHATSAPP_OTP_RESET';
export const FETCH_WHATSAPP_OTP_SUCCESS='FETCH_WHATSAPP_OTP_SUCCESS';

//PI download
export const FETCH_DOWNLOAD_PI_FAILURE = 'FETCH_DOWNLOAD_PI_FAILURE';
export const FETCH_DOWNLOAD_PI_SUCCESS = 'FETCH_DOWNLOAD_PI_SUCCESS';
export const FETCH_DOWNLOAD_PI_REQUEST = 'FETCH_DOWNLOAD_PI_REQUEST';
export const FETCH_DOWNLOAD_PI_RESET = 'FETCH_DOWNLOAD_PI_RESET';

//Packing list 
export const FETCH_DOWNLOAD_PACKING_LIST_FAILURE = 'FETCH_DOWNLOAD_PACKING_LIST_FAILURE';
export const FETCH_DOWNLOAD_PACKING_LIST_SUCCESS = 'FETCH_DOWNLOAD_PACKING_LIST_SUCCESS';
export const FETCH_DOWNLOAD_PACKING_LIST_REQUEST = 'FETCH_DOWNLOAD_PACKING_LIST_REQUEST';
export const FETCH_DOWNLOAD_PACKING_LIST_RESET = 'FETCH_DOWNLOAD_PACKING_LIST_RESET';