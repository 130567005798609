import actions from '../actions';
import {
  FETCH_DOWNLOAD_PI_FAILURE,
  FETCH_DOWNLOAD_PI_REQUEST,
  FETCH_DOWNLOAD_PI_RESET,
  FETCH_DOWNLOAD_PI_SUCCESS,
} from '../constants';

const initialState = {
  downloadPIDetailsRequest: false,
  downloadPIDetailsData: {},
  downloadPIDetailsStatus: false,
};

interface stateType {
  downloadPIDetailsRequest: boolean;
  downloadPIDetailsData: any;
  downloadPIDetailsStatus: boolean;
}

interface actionType {
  type: string;
  payload: {};
}

export const downloadPIDataReducer = (state = initialState, action: actionType): stateType => {
  switch (action.type) {
    case FETCH_DOWNLOAD_PI_REQUEST:
      return {
        ...state,
        downloadPIDetailsRequest: true,
        downloadPIDetailsData: {},
        downloadPIDetailsStatus: false,
      };
    case FETCH_DOWNLOAD_PI_SUCCESS:
      return {
        ...state,
        downloadPIDetailsRequest: false,
        downloadPIDetailsData: action.payload,
        downloadPIDetailsStatus: true,
      };
    case FETCH_DOWNLOAD_PI_FAILURE:
      return {
        ...state,
        downloadPIDetailsRequest: false,
        downloadPIDetailsData: action.payload,
        downloadPIDetailsStatus: false,
      };
    case FETCH_DOWNLOAD_PI_RESET:
      return {
        ...state,
        downloadPIDetailsRequest: false,
        downloadPIDetailsData: {},
        downloadPIDetailsStatus: false,
      };
    default:
      return {
        ...state,
      };
  }
};
